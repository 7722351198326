<template>
    <!--识图-->
    <video ref="videoRef" class="video_div" :style="style()" @touchstart="handleTouchStart" @touchend="handleTouchEnd"
        autoplay playsinline :poster="`/app_h5/image/Camera.png`"></video>
</template>

<script>
export default {
    props: ['module'],
    data() {
        return {
            translateX: 0,
            translateY: 0,
            dragging: false,
            lastX: 0,
            lastY: 0,
            windowWidth: 0,// 视口尺寸  
            windowHeight: 0,
            img_video: null,
            peerConnection: null,
            imgSocket: null
        };
    },

    computed: {
        style() {
            return function () {
                return {
                    'transform': `translate3d(${this.translateX}px, ${this.translateY}px, 0)`,
                    'top': this.module.top == 0 ? '44px' : (44 + this.module.top) + 'px'
                }
            }
        }
    },

    mounted() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    },

    methods: {

        //识图功能
        createSocket() {
            if (this.imgSocket == null) {
                this.imgSocket = new WebSocket('wss://mobile.kamikasi.net/image/');
                this.imgSocket.onopen = () => {
                    this.imgSocket.send("uuid:" + this.module.uuid)
                }
            }

            this.img_video = this.$refs.videoRef;
            this.img_video.srcObject = this.module.videoStream;
            // 等待视频元数据加载完成
            this.img_video.onloadedmetadata = () => {
                //截图
                setTimeout(() => {
                    this.photoGraphTimer()
                }, 300);
            }
        },

        //拍照计时器
        photoGraphTimer() {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            // 设置canvas大小与视频一致
            canvas.width = this.img_video.videoWidth;
            canvas.height = this.img_video.videoHeight;
            // 绘制视频帧到canvas
            context.drawImage(this.img_video, 0, 0, canvas.width, canvas.height);

            // 将canvas转换为Blob并下载
            canvas.toBlob((blob) => {
                this.imgSocket.send(blob);
                // 确保视频继续播放
                //this.img_video.play();
            }, 'image/jpeg', 0.5);
        },

        updateWindowDimensions() {
            this.windowWidth = window.innerWidth - 20;
            this.windowHeight = window.innerHeight - (this.module.top == 0 ? '44' : 44 + this.module.top) * 3;
        },

        handleTouchStart(e) {
            // 立即开始拖动  
            this.dragging = true;
            this.lastX = e.touches[0].clientX;
            this.lastY = e.touches[0].clientY;
            // 添加touchmove事件监听器 
            document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
            e.preventDefault();
        },

        handleTouchMove(e) {

            if (this.dragging) {
                let deltaX = e.touches[0].clientX - this.lastX;
                let deltaY = e.touches[0].clientY - this.lastY;

                // 计算新的位置  
                let newX = this.translateX + deltaX;
                let newY = this.translateY + deltaY;

                // 边界检查  
                newX = Math.min(Math.max(newX, 0), this.windowWidth - this.$refs.videoRef.offsetWidth);
                newY = Math.min(Math.max(newY, 0), this.windowHeight - this.$refs.videoRef.offsetHeight);

                // 更新位置  
                this.translateX = newX;
                this.translateY = newY;

                // 更新上一次触摸点  
                this.lastX = e.touches[0].clientX;
                this.lastY = e.touches[0].clientY;
            }
        },

        //拖动结束
        handleTouchEnd() {
            document.removeEventListener('touchmove', this.handleTouchMove);
            this.dragging = false;
            // 确保视频继续播放
            //this.img_video.play();
        },

        //清空
        clearUpdata() {
            //清除视口监听
            window.removeEventListener('resize', this.updateWindowDimensions);
        },
    }
};  
</script>

<style>
.video_div {
    position: absolute;
    z-index: 999;
    width: 100px;
    max-width: 100px;
    cursor: move;
    user-select: none;
    text-align: center;
    border-radius: 5px;
    left: 10px;
}
</style>