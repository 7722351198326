<template>
  <div id="app">
    <!--公共的过渡动画-->
    <animationExcessive v-if="!customize && !isShow" />
    <!--顶部导航-->
    <div v-if="isShow" class="header" :style="style(0)">
      <div :style="style(1)" class="back_view">
        <el-select v-if="!appFromBoolean" class="select" v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
        <img v-if="appFromBoolean" class="icon" src="/app_h5/image/arrow-left.png"
          @click="postMsg({ msg: '', type: 'back' })" />
        <div v-if="isShow" class="title">{{ jsonModule.modelClass }}</div>
        <!-- <img v-if="videoShow" src="/app_h5/image/video_icon.png" class="icon_video" @click="setVideoMedia" /> -->
      </div>
    </div>
    <!--定制化rtc前置页面-->
    <TempVideoCom v-if="customize && jsonModule" ref="tempVideoRef" :module="jsonModule" @sendRtc="sendRtc" />
    <!--可拖动的视频窗口-->
    <videoCom v-if="videoShow" ref="videoRef" @postMsg="postMsg" :module="jsonInfo" @videoPer="videoPer" />
    <!--three.js 3D模块-->
    <threeCom v-if="appFromType == '3d' && jsonModule" ref="threeScene" @show="show()" @asrSocket="asrSocket"
      @cleanMessageCount="cleanMessageCount" :module="jsonModule" @typewriterEffect="typewriterEffect" />
    <!--rtc 模块-->
    <rtcCom v-if="appFromType == 'rtc' && jsonModule && !customize" ref="rtcScene" @show="show()" @rtcFinsh="rtcFinsh"
      @rtcPeer="rtcPeer" @asrSocket="asrSocket" @sendRtc="sendRtc" :module="jsonModule"
      @typewriterEffect="typewriterEffect" />
    <!--语音动画-->
    <animationCom v-if="playBoolean" />
    <!--底部视频、语音、文字输入模块-->
    <inputCom v-if="isShow" ref="inputScene" @sendValue="sendValue" @animationWay="animationWay"
      @gainPowerVideo="gainPowerVideo"/>
  </div>
</template>

<script>
import { webviewGetMessage } from "./utils/appToWebview";
import WebSocketService from "./utils/websocket";
import animationExcessive from "./components/animationExcessive.vue";
import animationCom from "./components/animationCom.vue";
import threeCom from "./components/threeCom";
import rtcCom from "./components/rtcCom.vue";
import videoCom from "./components/videoCom";
import inputCom from "./components/inputCom";
import TempVideoCom from "./views/tempVideoCom.vue";

export default {
  components: {
    animationExcessive,
    animationCom,
    threeCom,
    rtcCom,
    videoCom,
    inputCom,
    TempVideoCom,
  },

  data() {
    return {
      jsonModule: null,
      wsService: null, //公共的消息接收以及llm的ws
      playBoolean: false, //音频动画
      options: [
        {
          value: "选项1",
          label: "3d",
        },
        {
          value: "选项2",
          label: "rtc",
        },
      ],
      value: localStorage.getItem("value") || "rtc",
      appFromType: "", //3d&rtc
      appFromBoolean: false, //是否是从app进入
      isShow: false,
      videoShow: false,
      modelName: "", //模型名称
      modelId: "", //模型id
      imageUrl: "", //传图片
      customize: true, //是否是定制化
      top: 0,
      uuid: null,
      videoStream: null,
      facingMode: "user", //user前置、environment后置
      jsonInfo: null,
      json: null, //测试数据
      inputShow: false,
      audioStream: null,
      messageCount: 0, // 新增的计数器变量进入
      playIndex: 0
    };
  },
  watch: {
    value(newVal) {
      setTimeout(() => {
        localStorage.setItem("value", newVal);
        this.value = newVal;
        window.location.reload();
      }, 1000);
    },
  },
  computed: {
    style() {
      return function (index) {
        return {
          height: index == 0 ? 44 + this.top + "px" : "44px",
          "align-items": "center",
          "padding-top": index == 1 ? this.top + "px" : "",
        };
      };
    },
  },
  created() {
    //接收app传参
    webviewGetMessage("msgFromApp", (params) => {
      this.postMsg({ msg: "人物准备完毕", type: "error" });
      this.appFromBoolean = true;
      this.jsonModule = JSON.parse(JSON.stringify(params));
      this.customize = this.jsonModule.customize;
      //如果是定制
      if (this.customize) {
        this.$nextTick(() => {
          this.$refs.tempVideoRef.openFullScreen();
        });
      }
      this.appFromType = this.jsonModule.modelType;
      this.top = this.jsonModule.top;
      //自定义rtc
      this.jsonModule.modelId = this.customize ? "" : this.jsonModule.modelId;
      this.jsonModule.modelClass = this.customize
        ? this.jsonModule.name
        : this.jsonModule.modelClass;
      this.jsonModule.imageUrl = this.customize ? "" : this.jsonModule.imageUrl;
      this.setParameter();
    });
  },

  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    //打开网页调试(打包app时注释)
    // this.gainPowerVideo();
    // this.opneWindow();
  },

  beforeDestroy() {
    // 清理资源，移除事件监听器
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },

  methods: {
    //打开网页调试(测试用例)
    opneWindow() {
      if (!this.appFromBoolean) {
        this.customize = false; //是否是定制化
        this.jsonModule = {
          appFromType: "rtc",
          name: "东方槊",
          voice: "f1",
          id_url: "https://aigc-files.bigmodel.cn/api/cogview/2024112517413766c65a4216eb47f0_0.png", //身份证
          img_url: "https://aigc-files.bigmodel.cn/api/cogview/2024112517413766c65a4216eb47f0_0.png", //用户上传的图片
          modelName: "dongfangshuo",
          modelClass: "东方槊",
          modelId: this.customize ? "" : "rtc_a",
          top: 0,
          uuid: "15111424807",
          imageUrl: this.customize ? "" : "/home/kmks-rtc/project/wav2lip/dongfangshuo_out_wav2lip", //是否是定制化'/home/kmks-rtc/project/wav2lip/dongfangshuo_out_wav2lip',//rtc本地或者定制
        };
        this.appFromType = this.jsonModule.appFromType;
        switch (this.appFromType) {
          case "3d":
            //3d模块
            this.$nextTick(() => {
              this.$refs.threeScene.Update();
            });
            break;
        }
        //如果是定制
        if (this.customize) {
          this.$nextTick(() => {
            this.$refs.tempVideoRef.openFullScreen();
          });
        }
        //注册接收消息的ws连接
        this.registerSocket();
      }
    },

    //设置样式,接收参数(app端)
    setParameter() {
      //如果是定制
      setTimeout(() => {
        switch (this.appFromType) {
          case "3d":
            //3d模块
            this.$refs.threeScene.Update();
            break;
        }
        //注册接收消息的ws连接
        this.registerSocket();
      }, 3000);
    },

    registerSocket() {
      this.postMsg({ msg: "电话已接通,等应答", type: "error" });
      this.wsService = new WebSocketService(this.$base.chat_ws);
      this.wsService.connect();
      // 发送注册消息
      setTimeout(() => {
        this.wsService.send(
          JSON.stringify({ type: "register", data: this.jsonModule.uuid })
        );
      }, 1000);

      // wss统一的消息处理函数
      const handleMessage = (message, type) => {
        if (
          type === "rtc" ||
          type === "viseme" ||
          type === "3d" ||
          type === "3d-azure"
        ) {
          //判断返回值是否开启识图
          if (message.read_pic === "True") {
            this.gainPowerVideo();
          }
          //返回的文本
          this.$refs.inputScene.upDateMessages(message.text, true);
          this.messageCount++; // 更新计数器
        } else if (type === "liveportrait") {
          if (message.error != "") {
            this.handleError(message.error)
          } else {
            this.jsonModule.imageUrl = message.directory;
            this.$refs.tempVideoRef.updateVideoLink(
              message.preview_url,
              message.name
            );
            this.$refs.tempVideoRef.clearCountdown();
          }
        } else if (type === "offer" && !this.customize) {
          this.$refs.rtcScene.Update(message);
        } else if (type === "visemeList") {
          //返回的嘴型及音频
          this.$refs.rtcScene.visemeList(message);
        } else if (type === "3D" || type === "3D-AZURE") {
          //返回的动作
          this.$refs.threeScene.modelWebSocket(message.value, this.messageCount);
        } else if (type === 'error') {
          this.$nextTick(() => {
            this.$refs.tempVideoRef.clearCountdown();
            this.postMsg({ msg: message.data || message.message, type: "back" });
          })
        }
      };

      // wss注册事件监听器
      const events = [
        "register",
        "offer",
        "directory",
        "rtc",
        "viseme",
        "3d",
        "3d-azure",
        "visemeList",
        "3D",
        "3D-AZURE",
        "answer",
        "error",
        "liveportrait",
      ];
      events.forEach((event) => {
        this.wsService.on(event, (message) => {
          if (
            event === "register" &&
            message.status !== "error" &&
            this.appFromType === "rtc"
          ) {
            this.sendRtc();
          } else {
            handleMessage(message, event);
          }
        });
      });
    },

    //公共打字机效果
    typewriterEffect(index) {
      this.$refs.inputScene.typewriterEffect(index);
    },

     //重置计数
     cleanMessageCount() {
      this.messageCount = 0;
    },

    sendRtc(type) {
      if (type == 0) {
        //点击自定义rtc和我聊天按钮
        this.customize = false;
      }
      const message = {
        uuid: this.jsonModule.uuid,
        target: "manager",
        type: this.customize ? "rtc_customer" : this.appFromType,
        model_name: this.appFromType,
        model_id: this.customize ? "" : this.jsonModule.modelId,
        directory: this.customize ? undefined : this.jsonModule.imageUrl, //本地rtc
        img_url: this.customize ? this.jsonModule : undefined, //自定义rtc
      };
      this.wsService.send(JSON.stringify(message));
    },

    //文本发送
    sendValue(value, appFromType) {
      this.wsService.send(
        JSON.stringify({
          uuid: this.jsonModule.uuid,
          text: value,
          target: "llm",
          model_name: appFromType,
          model_id: this.jsonModule.modelId,
          qid: "0",
          name: this.jsonModule.modelId == "" ? this.jsonModule.name : "",
        })
      );
    },

    //发送语音(动画)
    animationWay(boolean) {
      this.playBoolean = boolean;
    },

    //撮合信令
    rtcPeer(data) {
      this.wsService.send(data);
    },

    videoPer(data) {
      this.wsService.send(data);
    },

    //跳转viseme
    rtcFinsh(voice) {
      this.wsService.send(
        JSON.stringify({
          uuid: this.jsonModule.uuid,
          target: "manager",
          type: "rtc",
          status: "finish",
        })
      );
      this.wsService.send(
        JSON.stringify({
          uuid: this.jsonModule.uuid,
          target: "viseme",
          voice: voice,
        })
      );
      setTimeout(() => {
        this.asrSocket(1);
      }, 1000);
    },

    async gainPowerVideo() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        this.postMsg({ msg: "请开启摄像头权限", type: "permission" });
        return;
      }
      try {
        //获取视频流
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: this.facingMode },
        });
        //获取音频流
        //this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        if (this.videoStream) {
          setTimeout(() => {
            this.videoShow = true;
            this.setSendImg();
          }, 1000);
        }
      } catch (error) {
        //this.postMsg({ msg: '获取摄像头权限失败', type: 'error' });
        this.postMsg({ msg: "请开启摄像头权限", type: "permission" });
      }
    },

    //前后摄像头切换user前置、environment后置
    async setVideoMedia() {
      this.facingMode =
        this.facingMode == "environment" ? "user" : "environment";
      this.$refs.videoRef.clearUpdata(); //清除视频流模块中的耗时操作
      //清空视频流
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
      this.videoStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: this.facingMode },
      });
      this.setSendImg();
    },

    //模型加载完成
    show() {
      if (!this.customize) {
        this.isShow = true;
      }

      //注册asr
      setTimeout(() => {
        this.asrSocket(0);
      }, 1000);
    },

    //更新asr
    asrSocket(type) {
      var data = {
        modelName: this.jsonModule.modelName,
        modelClass:this.jsonModule.modelClass,
        appFromType:
          type == 0 ? this.appFromType : type == 1 ? "viseme" : "3d-azure",
        modelId: this.jsonModule.modelId,
        uuid: this.jsonModule.uuid,
      };

      this.$nextTick(() => {
        this.$refs.inputScene.Update(data);
      });
    },

    setSendImg() {
      this.jsonInfo = {
        modelName: this.jsonModule.modelName,
        top: this.jsonModule.top,
        uuid: this.jsonModule.uuid,
        videoStream: this.videoStream,
      };
      setTimeout(() => {
        this.$refs.videoRef.createSocket(); //识图功能(canvas传图)
      }, 1000);
    },

    handleBeforeUnload() {
      this.postMsg({ msg: "", type: "back" });
    },

    // 延时返回上一页
    handleError(error) {

      this.$nextTick(() => {
        this.$refs.tempVideoRef.clearCountdown();
        this.postMsg({ msg: error, type: "back" });
      });

      // this.postMsg({ msg: error, type: "error" })
      // setTimeout(() => {
      //  this.postMsg({ msg: "", type: "back" });
      // this.$refs.tempVideoRef.clearCountdown();
      // }, 2000)
    },

    //给app传递消息
    postMsg(info) {
      //返回键逻辑
      if (info.type == "back") {
        //缓存聊天历史记录
        this.$refs.inputScene.cacheMessages();

        if (this.videoStream) {
          this.videoStream.getTracks().forEach((track) => track.stop());
          this.videoStream = null;
          this.$refs.videoRef.clearUpdata(); //清除视频流模块中的耗时操作
        }

        switch (this.appFromType) {
          case "3d":
            //3d模块
            this.$refs.threeScene.clearUpdata(); //清除three.js中的模型
            break;
          case "rtc":
            //rtc模块
            if (this.$refs.rtcScene) {
              this.$refs.rtcScene.clearUpdata();
              this.wsService.send(
                JSON.stringify({
                  uuid: this.jsonModule.uuid,
                  target: "manager",
                  type: "rtc",
                  status: "finish",
                })
              );
            }
            break;
        }

        if (this.$refs.inputScene) {
          this.$refs.inputScene.clearUpdata(); //清除视频流模块中的耗时操作
        }
      }

      this.$webUni.postMessage({
        data: {
          action: "appSaveMsgInfo",
          params: info,
        },
      });
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #282828;
}

*::-webkit-scrollbar {
  display: none;
}

.your-scrollable-element::-webkit-scrollbar {
  display: none;
}

.firefox-hack {
  overflow: hidden;
}

.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 110;
  background: rgba(255, 255, 255, 0.5);
}

.back_view {
  display: flex;
  align-items: center;
  z-index: 2;
}

.select {
  position: absolute;
  left: -10px;
  width: 90px;
}

.icon {
  position: absolute;
  left: 10px;
  width: 30px;
  height: 25px;
}

.icon_video {
  position: absolute;
  right: 10px;
  width: 30px;
  height: 25px;
}

.title {
  font-size: 18px;
  font-weight: 800;
  z-index: 2;
}
</style>
