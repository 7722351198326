<template>
    <div class="content">
        <transition name="fade">
            <!--rtc模块-->
            <video v-if="rtcBoolean" ref="videoRtc" class="video" autoplay playsinline
                :poster="`/app_h5/image/Camera.png`"></video>
        </transition>
        <transition name="fade">
            <!--viseme模块-->
            <vismeCom v-if="visemeBoolean" ref="vismeScene" @visemeShow="visemeShow" @typewriterEffect="typewriterEffect" />
        </transition>
    </div>
</template>
<script>
import vismeCom from "../components/vismeCom.vue"
export default {
    components: {
        vismeCom,
    },
    props: ['module'],
    data() {
        return {
            message: '',
            peerConnection: null,
            rtc_video: null,//rtc推流路径
            countdown: 300, // 倒计时(300秒)
            timer: null,//计时器对象
            visemeBoolean: false,//是否显示viseme
            rtcBoolean: true,//是否显示rtc
            iceConnectionState: false,
            reconnectDown: 5,
            reconnectTimer: null
        }
    },

    mounted() {
        this.rtc_video = this.$refs.videoRtc;
    },

    methods: {

        //数据更新
        Update(data) {
            this.message = data;
            //建立信令RTC
            this.initRTCPeer(data);
            this.createSocket(data);
        },

        //创建一个websocket
        async createSocket(message) {
            if (message.sdp) {
                await this.peerConnection.setRemoteDescription(new RTCSessionDescription({
                    sdp: message.sdp,
                    type: message.type
                }));
                if (message.type === 'offer') {
                    const answer = await this.peerConnection.createAnswer();
                    await this.peerConnection.setLocalDescription(answer);
                    this.$emit('rtcPeer', JSON.stringify({ type: this.peerConnection.localDescription.type, sdp: this.peerConnection.localDescription.sdp, 'target': message.backend_client }))
                }
            } else if (message.candidate) {
                await this.peerConnection.addIceCandidate(new RTCIceCandidate(message.candidate));
            }
        },

        initRTCPeer(message) {
            // 添加STUN和TURN服务器配置
            const configuration = {
                iceServers: [
                    { urls: "stun:47.116.196.10:3480" },
                    { urls: "turn:47.116.196.10:3480", username: "kmksfront", credential: "kmks" }
                ]
            };
            this.peerConnection = new RTCPeerConnection(configuration);

            //监听连接状态变化事件
            this.peerConnection.oniceconnectionstatechange = () => {
                if (this.peerConnection.iceConnectionState == "connected") {
                    clearInterval(this.reconnectTimer);
                    this.reconnectTimer = null; // 清空计时器引用，防止重复调用
                    this.iceConnectionState = true
                    //推流中
                    // this.peerConnection.getStats(null).then(stats => {
                    //     stats.forEach(report => {
                    //         console.log(report);
                    //     });
                    // }).catch(error => {
                    //     console.error(`Error during getStats(): ${error}`);
                    // });

                    //RTC连接成功
                    this.$emit('show')

                    //开始3分钟计时
                    if (this.module.modelId != '') {
                        this.startCountdown();
                    }

                    //缓存加载过的模型到历史列表
                    this.postMsg({ msg: this.module, type: 'addModule' })
                }
            };

            this.peerConnection.ontrack = (event) => {
                if (event.streams && event.streams[0].getVideoTracks().length > 0) {
                    const track = event.streams[0].getVideoTracks()[0];
                    if (track) {
                        // 如果当前有视频元素与 RTCPeerConnection 相关联，应停止并重新赋值
                        if (this.rtc_video.currentSrc) {
                            const existingStream = this.rtc_video.currentSrcObject;
                            if (existingStream) {
                                existingStream.getTracks().forEach(track => track.stop());
                            }
                        }
                        this.rtc_video.srcObject = event.streams[0];
                    }
                }
            };

            this.peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    this.$emit('rtcPeer', JSON.stringify({ type: 'candidate', candidate: event.candidate, 'target': message.backend_client }))
                } else {
                    //ice收集完成开始计时
                    this.startRtcReconnect();
                }
            };


        },

        //三分钟计时器
        startCountdown() {
            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null; // 清空计时器引用，防止重复调用
                    this.visemeBoolean = true;
                    this.$nextTick(() => {
                        this.$refs.vismeScene.Update(this.module);
                    })
                }
            }, 1000);
        },

        //重连计时器
        startRtcReconnect() {
            this.reconnectTimer = setInterval(() => {
                if (this.reconnectDown > 0) {
                    this.reconnectDown--;
                } else {
                    //连接匹配失败重发
                    if (!this.iceConnectionState) {
                        clearInterval(this.reconnectTimer);
                        this.reconnectTimer = null; // 清空计时器引用，防止重复调用
                        this.$emit('sendRtc');//连接匹配失败进行重连
                    }
                }
            }, 1000);
        },

        //viseme显示
        visemeShow(voice) {
            setTimeout(() => {
                this.rtcBoolean = false;
                this.$emit('rtcFinsh', voice);
                this.clearUpdata();
            }, 1000);

        },

        typewriterEffect(index) {
            //逐个打印文字
            this.$emit('typewriterEffect', index);
        },

        //给app传递消息
        postMsg(info) {
            this.$webUni.postMessage({
                data: {
                    action: "appSaveMsgInfo",
                    params: info,
                }
            })
        },

        visemeList(data, context) {
            this.$refs.vismeScene.getVismeInfo(data, context);
        },

        //清空数据
        clearUpdata() {
            //清除页面计时器
            clearInterval(this.timer);
            this.timer = null; // 清空计时器引用，防止重复调用

            //关闭rtc
            if (this.peerConnection) {
                this.peerConnection.close();
                this.peerConnection = null;
            }
        }
    }
}
</script>
<style scoped>
.content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fade-enter-active {
    transition: opacity 0.1s;
}

.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.video {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    position: absolute;
    z-index: 2;
}
</style>