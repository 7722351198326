<template>
  <div
    class="temp-box"
    v-loading="isLoading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="content" v-if="!isLoading">
      <div class="action-box" v-if="videoSource">
        <div class="module-box">
          <div class="video-box">
            <video
              ref="videoPlayer"
              autoplay
              loop
              controls
              controlslist="nodownload"
              muted="true"
              style="width: 100%; height: 100%; object-fit: fill"
              webkit-playsinline=""
              playsInline
              x5-playsinline
              t7-video-player-type="inline"
              x5-video-player-type="h5-page"
              x-webkit-airplay="allow"
              disablePictureInPicture
              :key="videoSource"
            >
              <source :src="videoSource" type="video/mp4" />
            </video>
          </div>
        </div>
        <div class="bottom-box">
          <div class="action-box">
            <div class="name">{{ updateName }}</div>
            <div class="desc">
              你好呀，我叫
              {{ updateName }},由AI生成,赶紧点击下方按钮，和我聊天吧
            </div>
            <div class="btn-group">
              <div class="btn-item chat-me" @click="sendRtc()">
                <img src="../assets/image/chat_icon.png" class="iconStyle" />
                <div class="text">和我聊天</div>
              </div>
              <div class="btn-item" @click="backPage()">
                <img src="../assets/image/back.png" class="iconStyle" />
                返回重置
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty description="请返回重试" v-else></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false, // 控制 LoadingPage 显示
      countdown: 180, // 倒计时初始值
      countdownInterval: null, // 定时器实例
      loadingText: "加载中，请稍候...", // 加载提示文本
      videoSource: "",
      updateName: "",
    };
  },
  mounted() {
    console.log("A=A==A==module===", this.module);
  },

  methods: {
    // 返回
    backPage() {
      this.postMsg({ msg: "", type: "back" });
    },

    sendRtc() {
      this.postMsg({ msg: "和我聊天", type: "error" });
      this.$emit("sendRtc", 0);
    },

    postMsg(info) {
      // this.isCalling = false; // 更新通话状态
      this.$webUni.postMessage({
        data: {
          action: "appSaveMsgInfo",
          params: info,
        },
      });
    },

    openFullScreen() {
      this.isLoading = true;
      this.startCountdown();
    },
    updateVideoLink(url, name) {
      console.log("子组件===", url);
      this.videoSource = `${url}?t=${new Date().getTime()}`;
      this.updateName = name;
    },

    // 启动倒计时
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
          // 更新加载提示文本
          this.loadingText = `加载中，请稍候...（预计剩余时间：${this.countdown}秒）`;
        } else {
          // 倒计时结束自动停止
          this.clearCountdown();
          this.handleTimeout();
        }
      }, 1000);
    },

    handleTimeout() {
      // 3分钟倒计时结束，未收到制作消息

      this.$nextTick(() => {
        this.backPage();
        this.postMsg({ msg: "正在排队中，请稍后重试~", type: "error" });
      });
    },

    // 清空倒计时
    clearCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
        this.countdown = 180; // 重置倒计时
        this.loadingText = "加载中，请稍候..."; // 恢复默认文本
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.temp-box >>> .el-loading-spinner {
  font-size: 50px;
}

.temp-box {
  background: #f9f9f9;
  min-height: 100vh;
  overflow: auto;
}

.module-box {
  /* margin: 15px; */
  /* padding: 15px; */
  background-color: #fff;
}

.video-box {
  width: 100%;
}

.action-box {
  padding: 15px;
  margin: 15px;
  border-radius: 20px;
  background-color: #fff;
}

.name {
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.desc {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555353;
}

/* 按钮 */
.btn-group {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.iconStyle {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.btn-item {
  background-color: rgba(128, 0, 128, 0.7);
  color: #fff;
  padding: 10px 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.chat-me {
  background: #f56c6c;
  color: #fff;
  padding: 10px 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.header-box {
  display: flex;
  padding: 8px 20px;

  /* height: 45px; */
  /* line-height: 45px; */
}

.icon-box {
  font-size: 28px;
}
</style>
