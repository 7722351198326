<template>
    <div class="content">
        <div class="avatar-container">
            <img src="../assets/image/yes_login.png" alt="Avatar" class="avatar animated-avatar">
            <div class="wave"></div>
            <div class="wave_"></div>
        </div>
        <div class="btm_div">
            <div class="text">对话接通中</div>
            <div class="container">
                <div class="line" v-for="(line, index) in lines" :key="index" :style="lineStyles[index]">
                </div>
            </div>
            <div class="phone_div" @click="postMsg">
                <img class="call_img" src="../assets/image/call_img.png">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lines: Array.from({
                length: 5
            }, () => ({})),
            animationInterval: null,
            animationFrame: 0,
            colors: ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF5733'], // 定义颜色数组
            isCalling: true, 
        };
    },
    watch: {  
        isCalling(newVal) {  
            if (!newVal) {  
                clearInterval(this.animationInterval);  
                this.resetAnimation();
            }  
        },  
    },
    computed: {
        // 使用计算属性来根据animationFrame计算每条线的样式  
        lineStyles() {
            return this.lines.map((_, index) => {
                const colorIndex = (this.animationFrame + index) % this.colors.length;
                const color = this.colors[colorIndex];
                return {
                    backgroundColor: color, // 应用颜色  
                };
            });
        },
    },
    mounted() {
        // 开始动画  
        this.startAnimation();
    },
    beforeDestroy() {
        // 清除动画定时器  
        if (this.animationInterval) {
            clearInterval(this.animationInterval);
        }
    },
    methods: {
        startAnimation() {
            this.animationInterval = setInterval(() => {
                this.animationFrame++;
            }, 300);
        },

        //挂断
        postMsg() {
            this.isCalling = false; // 更新通话状态  
            this.$webUni.postMessage({
                data: {
                    action: "appSaveMsgInfo",
                    params: { msg: '', type: 'back' },
                }
            })
        },
        
        resetAnimation() {  
            this.animationFrame = 0; // 重置动画帧
        },
    },
};
</script>
<style scoped>
.content {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    background: #282828;
    display: flex;
    justify-content: center;
    position: absolute;
}

.avatar-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin-top: 80px;
}

.avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #fff;
    /* 初始状态下不应用动画 */
    transition: none;
    position: absolute;
    z-index: 1;
}

/* 新增的动画类，用于脉冲式缩放 */
.animated-avatar {
    animation: pulseAvatar 1.5s infinite ease-in-out;
}

@keyframes pulseAvatar {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

.wave {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    transform: translate(-50%, -50%);
    animation: expandWave 1.5s infinite linear;
}

.wave_ {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    transform: translate(-50%, -50%);
    animation: expandWave_ 1.5s infinite linear;
}

@keyframes expandWave_ {
    0% {
        width: 200px;
        height: 200px;
        opacity: 0.3;
    }

    100% {
        width: 280px;
        height: 280px;
        opacity: 0.01;
    }
}

@keyframes expandWave {
    0% {
        width: 200px;
        height: 200px;
        opacity: 0.3;
    }


    100% {
        width: 350px;
        height: 350px;
        opacity: 0.01;
    }
}

.btm_div {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 999;
}

.text {
    color: #fff;
    font-size: 16px;
    padding: 15px 0;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.line {
    width: 10px;
    height: 10px;
    margin: 5px 5px;
    border-radius: 50%;
    background: #282828;
    transition: background-color 0.3s ease-in-out;
}

.phone_div {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}

.call_img {
    width: 35px;
    height: 35px;
}
</style>