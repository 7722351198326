var url = 'https://mobile.kamikasi.net'
var threeJs_ws = 'wss://mobile.kamikasi.net/websocket/3090/'
var rct_ws = 'wss://mobile.kamikasi.net/websocket/app/'
var slServer = 'wss://mobile.kamikasi.net/websocket/3090/6667/'

//消息接收以及文字发送ws
var chat_ws = 'wss://mobile.kamikasi.net/rtc/chat/'
//语音ws
var asr_ws = 'wss://mobile.kamikasi.net/websocket/app/9951/'
const base = {
	url,
	threeJs_ws,
	rct_ws,
	chat_ws,
	slServer,
	asr_ws
}
export default base