// websocket.js
class WebSocketService {
    constructor(url) {
        this.url = url;
        this.socket = null;
        this.listeners = {};
        this.reconnectInterval = 5000; // 重连间隔
        this.maxReconnectAttempts = 5; // 最大重连次数
        this.reconnectAttempts = 0; // 当前重连次数
        this.isReconnecting = false; // 是否正在重连
    }

    // 初始化 WebSocket 连接
    connect() {
        this.socket = new WebSocket(this.url);

        this.socket.onopen = () => {
            this.isReconnecting = false; // 连接成功，重连标志设为 false
            this.reconnectAttempts = 0; // 重置重连次数
        };

        this.socket.onmessage = (event) => {
            this.handleMessage(event.data);
        };

        this.socket.onclose = () => {
            this.reconnect(); // 尝试重连
        };

        this.socket.onerror = (error) => {
            console.error(`WebSocket错误: ${this.url}`, error);
        };
    }

    // 处理接收到的消息
    handleMessage(data) {
        var message = ''
        if (data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    //此处blob后端用的是UTF-8编码的二进制JSON
                    const jsonString = new TextDecoder('utf-8').decode(e.target.result);
                    message = { 'type': JSON.parse(jsonString).type, 'value': data };
                    if (this.listeners[message.type]) {
                        this.listeners[message.type].forEach(callback => callback(message));
                    }
                } catch (error) {
                    // 处理解析错误
                }
            };
            // 读取blob数据
            reader.readAsArrayBuffer(data);
        } else {
            message = JSON.parse(data);
            if (this.listeners[message.type]) {
                this.listeners[message.type].forEach(callback => callback(message));
            }
        }
    }

    // 发送消息
    send(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            console.error('WebSocket未连接，无法发送消息');
        }
    }

    // 注册消息监听器
    on(type, callback) {
        if (!this.listeners[type]) {
            this.listeners[type] = [];
        }
        this.listeners[type].push(callback);
    }

    // 关闭 WebSocket 连接
    close() {
        if (this.socket) {
            this.socket.close();
        }
    }

    // 重连机制
    reconnect() {
        if (this.isReconnecting || this.reconnectAttempts >= this.maxReconnectAttempts) return; // 如果正在重连或达到最大重连次数，则不再执行
        this.isReconnecting = true;
        this.reconnectAttempts++;

        setTimeout(() => {
            console.log(`尝试重连: ${this.url} (尝试次数: ${this.reconnectAttempts})`);
            this.connect(); // 重新连接
        }, this.reconnectInterval);
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}

export default WebSocketService;