<template>
    <div class="page">
        <!--对话框-->
        <div class="chat-container" @touchstart="handleTouchStart" @touchend="handleTouchEnd" :style="setHeight">
            <div class="messages-container" ref="messagesContainer">
                <div v-for="(item, index) in messages" :key="index" :class="!item.is_AI ? 'right' : 'left'">
                    <span>{{ item.text }}</span>
                </div>
            </div>
        </div>
        <!--键盘输入样式-->
        <div v-show="inputType" class="input_div">
            <!--可视化-->
            <div :style="style(0)" @click="itemClick(0)">
                <img class="img" src="../assets/image/camera_img.png" />
            </div>
            <!--文字输入-->
            <el-input ref="myInput" class="input" v-model="inputValue" placeholder="和TA聊聊天吧~" />
            <!--发送文字/切换麦克风输入-->
            <div @click="send" :style="style(1)">
                <img :style="imgStyle" :src="require(`@/assets/image/${imgType}.png`)" />
            </div>
        </div>
        <!--语音输入样式-->
        <div v-show="!inputType" class="input_div_">
            <!--可视化-->
            <div class="div_item" @click="itemClick(0)">
                <img :style="imgStyle_(0)" src="@/assets/image/camera_img.png" />
            </div>
            <!--语音-->
            <div class="div_item" @mousedown="handleMouseDown" @mouseup="handleMouseUp" @mouseleave="handleMouseLeave"
                @touchstart="handleMouseDown" @touchend="handleMouseUp" @touchcancel="handleMouseLeave">
                <img :style="imgStyle_(1)" src="@/assets/image/micro_img.png" @click.stop />
            </div>
            <!--切换到键盘输入-->
            <div class="div_item" @click="itemClick(1)">
                <img :style="imgStyle_(2)" src="@/assets/image/keyboard_img.png" />
            </div>
        </div>
    </div>
</template>
<script>
import WebSocketService from '../utils/websocket'
export default {
    data() {
        return {
            module: null,//模型数据
            imgArr: ['camera_img', 'micro_img', 'keyboard_img'],
            inputType: true,//输入方式
            imgType: 'micro_img',
            inputValue: '',
            asr_ws: null,//语音ws对象
            timer: null, // 长按计时器
            audioStream: null,//音频对象
            audioContext: null,
            source: null,
            processor: null,
            messages: [],
            startY: 0,
            endY: 0,
            isScrollingDown: false,
            appFromType: null,
            pendingMessages: [], // 用于存储传入的消息
            combinedMessage: '',
            playBoolean: true,
            rtcIndex: 0,
            rtcPlayBoolean: true,
            cachedBoolean: true,
            messagesIndex:0            
        }
    },
    watch: {
        //监听输入框变化
        inputValue(newValue) {
            if (newValue.trim() !== '') {
                //有文字输入
                this.imgType = 'send_img'
            } else {
                //无内容
                this.imgType = 'micro_img'
            }
            this.isScrollingDown = false
        },
    },
    computed: {
        style() {
            return function (index) {
                return {
                    'display': 'flex',
                    'width': index == 0 ? '45px' : '',
                    'height': index == 0 ? '45px' : '',
                    'justify-content': 'center',
                    'align-items': 'center',
                    'margin': index == 1 ? '3px 3px' : '',
                    'padding': index == 1 ? '8px 8px' : '',
                    'border-radius': index == 1 ? '100%' : '',
                    'background': index == 0 ? '' : this.imgType == 'micro_img' ? 'rgba(255, 255, 255, 0.1)' : '#1296db'
                }
            }
        },
        imgStyle() {
            return {
                'width': this.imgType == 'send_img' ? '27px' : '30px',
                'height': this.imgType == 'send_img' ? '27px' : '30px',
                'padding': this.imgType == 'send_img' ? '3px 3px 0 0' : ''
            }
        },
        imgStyle_() {
            return function (index) {
                return {
                    'width': index == 1 ? '40px' : '30px',
                    'height': index == 1 ? '40px' : '30px',
                    'padding': index == 1 ? '13px' : '18px'
                }
            }
        },
        setHeight() {
            return {
                'max-height': this.isScrollingDown ? '75%' : '15%',
            }
        }
    },

    methods: {

        Update(data) {
            this.module = data;
            if (this.module.appFromType == 'viseme') {
                this.playEnd();
            }
            //注册语音webSocket连接
            this.createSocket();
            if (this.cachedBoolean) {
                this.cachedBoolean = false
                this.loadCachedMessages();
            }
        },

        //注册语音webSocket连接
        createSocket() {
            if (this.asr_ws == null) {
                //注册
                this.asr_ws = new WebSocketService(this.$base.asr_ws);
                //初始化
                this.asr_ws.connect();
            }

            //wss消息监听器
            this.asr_ws.on(this.module.appFromType, this.handleMessage.bind(this));

            //发送消息
            setTimeout(() => {
                if (this.appFromType == null || this.appFromType != this.module.appFromType) {
                    this.asr_ws.send(JSON.stringify({ "model_name": this.module.appFromType, "uuid": this.module.uuid, 'model_id': this.module.modelId, 'name': this.module.modelId == '' ? this.module.modelName : '' }));
                    this.appFromType = this.module.appFromType;
                }
            }, 1000);
        },

        //消息接收
        handleMessage(message) {
            if (message.type === '3d' || message.type === '3d-azure' || message.type === 'rtc' || message.type === 'viseme') {
                this.upDateMessages(message.text, false)
                return
            }
        },

        async gainPower() {
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                this.postMsg({ msg: '请开启麦克风权限', type: 'permission' });
                return;
            }
            try {
                //获取音频流
                this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                //切换到麦克风
                setTimeout(() => {
                    this.inputType = false
                }, 100);
            } catch (error) {
                this.postMsg({ msg: '请开启麦克风权限', type: 'permission' });
            }
        },

        //监听用户长按开始
        handleMouseDown(event) {
            // 清除之前的计时器，以防多次触发
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            // 设置计时器
            this.timer = setTimeout(() => {
                //发送语音
                this.startRecording();
            }, 10);
            // 阻止默认行为
            event.preventDefault();
        },

        //监听用户长按结束
        handleMouseUp(event) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
                //结束语音
                this.stopRecording();
            }
            event.preventDefault();
        },

        //监听用户长按结束
        handleMouseLeave(event) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
                //结束语音
                this.stopRecording();
            }
            event.preventDefault();
        },

        //开始录音
        startRecording() {
            if (!this.audioStream) {
                //授权麦克风
                this.postMsg({ msg: '请允许访问您的麦克风', type: 'permission' });
                return;
            }
            this.$emit('animationWay', true);
            var that = this;
            //获取话筒权限
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.source = this.audioContext.createMediaStreamSource(this.audioStream);
            this.processor = this.audioContext.createScriptProcessor(16384, 1, 1);

            this.processor.onaudioprocess = (e) => {
                const input = e.inputBuffer.getChannelData(0);
                const buffer = new Int16Array(input.length);
                for (let i = 0; i < input.length; i++) {
                    buffer[i] = Math.min(input[i] * 32767, 32767); // 转换为16位整数并防止溢出
                    buffer[i] = Math.max(buffer[i], -32768); // 防止下溢
                }
                that.asr_ws.send(new Int8Array(buffer.buffer)); // 发送 ArrayBuffer
            };

            this.source.connect(this.processor);
            // 如果不需要监听输出，则不连接到 audioContext.destination
            this.processor.connect(this.audioContext.destination);
        },

        //结束录音
        stopRecording() {
            this.$emit('animationWay', false);
            // 停止音频处理器
            if (this.processor) {
                this.processor.disconnect(); // 断开音频处理器的连接
                this.processor.onaudioprocess = null; // 清除事件处理器
                this.processor = null; // 清除处理器引用
            }

            // 停止音频源
            if (this.source) {
                this.source.disconnect(); // 断开音频源的连接
                this.source = null; // 清除源引用
            }

            // 发送一个结束录音的信号给语音转文字webSocket服务器
            this.asr_ws.send("end");
        },

        //点击切换麦克风/发送文本
        send() {
            if (this.imgType == 'micro_img') {
                //授权麦克风
                this.gainPower();
            } else {
                //发送文本
                this.upDateMessages(this.inputValue, false);
                this.$emit('sendValue', this.inputValue, this.module.appFromType)
                this.inputValue = ''
            }
        },

        //将返回数据插入数组
        upDateMessages(value, boolean) {
            if (boolean) {
                this.pendingMessages.push(value)
                if (this.module.appFromType == 'rtc' && this.rtcPlayBoolean) {
                    this.rtcPlayBoolean = false;
                    setTimeout(() => {
                        this.typewriterEffect(0);
                    }, 1000);
                }
            } else {
                this.messagesIndex = this.messages.length + 1;
                this.playBoolean = true;
                console.log('=this.messagesIndex====',this.messagesIndex);
                this.messages.push({
                    text: value,
                    is_AI: false,
                });
                this.scrollToBottom();
            }
        },

        //回复话术以打字机效果呈现
        typewriterEffect(palyIndex) {
            if (palyIndex < this.pendingMessages.length) {
                this.combinedMessage = this.pendingMessages[palyIndex];
                let charIndex = 0;
                const interval = setInterval(() => {
                    if (charIndex < this.combinedMessage.length) {
                        if (this.playBoolean) {
                            this.playBoolean = false
                            this.messages.push({
                                text: '',
                                is_AI: true,
                            });
                        }
                        this.messages[this.messagesIndex].text += this.combinedMessage[charIndex];
                        charIndex++;
                        this.scrollToBottom();
                    } else {
                        if (this.module.appFromType == 'rtc' && palyIndex < this.pendingMessages.length - 1) {
                            this.rtcIndex++;
                            setTimeout(() => {
                                this.typewriterEffect(this.rtcIndex);
                            }, 800);
                        }
                        if (palyIndex == this.pendingMessages.length - 1) {
                            //所有内容回答完成
                            this.playEnd()
                        }
                        clearInterval(interval);
                    }
                }, 180); // 调整打字机效果的速度
            }
        },

        playEnd() {
            console.log('A=A==清空对话===')
            this.rtcPlayBoolean = true;
            this.rtcIndex = 0;
            this.playBoolean = true;
            this.charIndex = 0;
            this.pendingMessages = [];
            this.combinedMessage = '';
            this.messagesIndex = 0
        },

        //始终显示最新一条(设置对话滑动到底部)
        scrollToBottom() {
            this.$nextTick(() => {
                const messagesContainer = this.$refs.messagesContainer;
                if (messagesContainer) {
                    messagesContainer.scrollTop = messagesContainer.scrollHeight;
                }
            });
        },

        itemClick(index) {
            switch (index) {
                case 0:
                    //可视化
                    this.$emit('gainPowerVideo')
                    break;
                case 1:
                    //切换到键盘输入
                    this.inputType = true;
                    break;
            }
        },

        handleTouchStart(event) {
            // 记录触摸开始时的Y坐标  
            this.startY = event.touches[0].clientY;
        },
        handleTouchEnd(event) {
            // 记录触摸结束时的Y坐标  
            this.endY = event.changedTouches[0].clientY;
            // 判断滑动方向  
            if (this.startY < this.endY) {
                this.isScrollingDown = true;
            } else if (this.startY > this.endY) {
                this.isScrollingDown = false;
            }

            // 重置状态以便下次触摸  
            this.startY = 0;
            this.endY = 0;
        },

        //缓存对话内容
        cacheMessages() {
            // 将对话内容存储到 localStorage
            localStorage.setItem(`chat_${this.module.modelClass}_${this.module.uuid}`, JSON.stringify(this.messages));
        },

        //获取对话内容
        loadCachedMessages() {
            // 从 localStorage 中加载对话内容
            const cachedMessages = localStorage.getItem(`chat_${this.module.modelClass}_${this.module.uuid}`);
            if (cachedMessages) {
                this.messages = JSON.parse(cachedMessages);
                this.scrollToBottom();
            }
        },

        //给app发送消息
        postMsg(info) {
            this.$webUni.postMessage({
                data: {
                    action: "appSaveMsgInfo",
                    params: info,
                }
            })
        },

        clearUpdata() {
            //清除音频流并关闭麦克风
            if (this.audioStream) {
                this.audioStream.getTracks().forEach(track => track.stop());
                this.audioStream = null;
            }
        }
    }
}
</script>
<style>
.page {
    width: 100vw;
    max-height: 100%;
}

.chat-container {
    position: fixed;
    bottom: 105px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-end;
    z-index: 110;
}

.messages-container {
    overflow-y: auto;
    margin: 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* 确保容器有高度可以滚动 */
}

.right {
    align-self: flex-end;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 5px 0 5px 50px;
    font-size: 13px;
    line-height: 20px;
}

.left {
    align-self: flex-start;
    background: rgba(255, 255, 255, .5);
    color: #000;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 5px 50px 5px 0;
    font-size: 13px;
    line-height: 20px;
}

.input_div {
    position: fixed;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    bottom: 20px;
    width: calc(100% - 60px);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    padding: 0 0 0 15px;
    margin: 0 20px;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.5);
    z-index: 9;
}

.input_div_ {
    position: fixed;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    bottom: 20px;
    width: 100%;
    justify-content: space-evenly;
    z-index: 9;
}

.div_item {
    display: flex;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.5);
}

.img {
    width: 30px;
    height: 30px;
}

.input .el-input__inner {
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: 18px;
    color: #fff !important;
    padding: 0 10px 0 5px;
}
</style>