<template>
	<div class="page_div">
		<div class="container">
			<div class="line" v-for="(line, index) in lines" :key="index"
				:style="{ animationDelay: `${index * 0.2}s` }"></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			lines: Array.from({ length: 16 }, () => ({})),
		};
	},
};
</script>
<style scoped>
.page_div {
	display: flex;
	position: fixed;
	bottom: 100px;
	background: none;
	width: 100vw;
	justify-content: center;
	z-index: 120;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 150px;
	height: 20px;
	left: 50%;
	box-sizing: border-box;
}

.line {
	width: 3px;
	height: 4px;
	border-radius: 20px;
	background: linear-gradient(180deg, rgba(255, 0, 0, 1), rgba(128, 0, 128, 1));
	animation: stretch-up-down 0.4s infinite ease-in-out alternate;
}

@keyframes stretch-up-down {
	0% {
		height: 4px;
	}

	100% {
		height: 20px;
	}
}
</style>