<template>
    <div>
        <div v-show="bubbleShow" class="thought-bubble">
            <div class="thought-text">试试转动我吧!</div>
            <div class="small-bubble"></div>
            <div class="tiny-bubble"></div>
        </div>
        <div class="threeScene" ref="threeScene"></div>
    </div>
</template>
<script>
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export default {
    props: ['module'],
    data() {
        return {
            scene: null,
            camera: null,
            renderer: null,
            clock: null,
            currentModel: null,
            mixer: null,
            currentAnimation: null,
            animationId: null,
            blendShapeMesh: null,//脸部表情mesh
            teethOneMesh: null,//上齿
            teethTwoMesh: null,//下齿
            eyeMesh: null,//眼睛mesh
            playNextBoolean: true,//流式传输时确保方法只执行一次
            actionArr: [],//动作数组
            audioBuffers: [],//音频数组
            blendshapeArr: [],//脸部表情数组
            currentBufferIndex: 0,//当前要播放的音频索引
            playNextAudioTimer: null,
            modelSocket: null,
            reconnectTime: null,
            actionType: true,
            audioContext: null,
            currentTime: null,
            currentFrame: null,
            framesPerSecond: null,
            updateInterval: null,
            updateTimer: null,
            animation: false,//内置对话动作状态
            timerBoolean:true,
            countdown: 180, // 3分钟的倒计时(180秒)
            timer: null,//计时器对象
            messageCount: 0,
            bubbleShow: false
        }
    },

    methods: {
        //更新数据
        Update() {
            this.init();
        },

        init() {

            // 场景，相机
            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color(0xffffff);
            this.camera = new THREE.PerspectiveCamera(7, window.innerWidth / window.innerHeight, 1, 1000);
            this.camera.position.set(0, 0, this.modelSize(this.module.modelName));//相机位置

            // 创建一个环境光源，它会向所有方向均匀发射光线
            const ambientLight = new THREE.AmbientLight(0xffffff, 2); //光源，强度为2
            this.scene.add(ambientLight);

            // 设置点光源的位置
            const pointLight = new THREE.PointLight(0xffffff, 500, 1000); //光源，衰减距离为1000
            pointLight.castShadow = true; // 启用阴影投射
            pointLight.position.set(3, 7, 15); // 光源位置
            // 将点光源添加到场景中
            this.scene.add(pointLight);

            //模型影子接收
            const planeMat = new THREE.MeshLambertMaterial();
            const plane = new THREE.Mesh(new THREE.PlaneGeometry(50, 50), planeMat);
            plane.position.set(0, -0.9, 0);
            plane.receiveShadow = true;
            plane.rotation.x = -Math.PI * 0.5;
            this.scene.add(plane);

            // 渲染器
            this.renderer = new THREE.WebGLRenderer({ antialias: true });//去掉锯齿
            this.renderer.shadowMap.enabled = true; //启用阴影投射
            this.renderer.antialias = true;
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.$refs.threeScene.appendChild(this.renderer.domElement);

            // 控制器
            let control = new OrbitControls(this.camera, this.renderer.domElement);
            control.enableZoom = true; // 缩放
            control.enablePan = true; // 平移
            control.maxPolarAngle = Math.PI / 2; //2.8 1.8 Math.PI / 1

            //创建一个时钟对象Clock
            this.clock = new THREE.Clock();

            //加载模型
            this.loaderModel();

            //窗口监视
            window.addEventListener('resize', this.onWindowResize, false);

        },


        loaderModel() {

            //加载没有动画的glb模型文件
            const loader = new GLTFLoader()
            loader.load(`/app_h5/model/${this.module.modelName}.glb`, (gltf) => {

                if (this.currentModel) {
                    this.scene.remove(this.currentModel)
                }
                //保存当前模型实例，方便后续操作
                this.currentModel = gltf.scene;
                this.currentModel.position.set(0, this.modelAdatation(this.module.modelName), 0)
                //将模型添加到场景中
                this.scene.add(this.currentModel);

                // 停止前一个动画（如果存在）
                if (this.mixer) {
                    this.mixer.stopAllAction();
                    this.mixer.uncacheRoot(gltf);//释放内存
                }

                //创建动画混合器
                this.mixer = new THREE.AnimationMixer(this.currentModel);
                // 初始化动画状态
                this.currentAnimation = this.mixer.clipAction(gltf.animations[0]);
                // 设置动画的结束回调
                this.currentAnimation.setLoop(THREE.LoopRepeat);//循环播放动作
                this.currentAnimation.play();//播放动画

                //递归遍历currentModel包含所有的模型节点
                this.currentModel.traverse((child) => {
                    //防止模型穿模
                    child.frustumCulled = false;
                    //设置模型投影
                    child.castShadow = true;
                    /**
                     * child.isMesh:判断模型对象child是不是网格模型'Mesh'
                     * child.name:判断约定使用的模型节点名称为Head
                    */

                    if (child.isMesh) {

                        if (child.name == 'eye001' || child.name == "Genesis8Female001") {
                            // 将所有 BlendShape 权重设置为 0
                            for (let i = 0; i < child.morphTargetInfluences.length; i++) {
                                child.morphTargetInfluences[i] = 0;
                            }
                        }

                        if (child.name == 'Teeth_1') {
                            this.teethOneMesh = child;
                            return false; // 找到后中断遍历
                        }

                        if (child.name == 'Teeth_2') {
                            this.teethTwoMesh = child;
                            return false; // 找到后中断遍历
                        }


                        //脸部表情
                        if (child.name == 'Head' || child.name == 'Head_1' || child.name == 'Head001') {
                            this.blendShapeMesh = child;
                            return false; // 找到后中断遍历
                        }

                        //调整眼球位置
                        if (child.name == 'Eye' || child.name == 'EyeOcclusion' || child.name == 'TearLine' || child.name == 'Lashes' || child.name == 'Brow') {
                            this.eyeMesh = child;
                            return false; // 找到后中断遍历
                        }

                        child.material.depthTest = true;    // 保持深度测试开启
                        switch (child.name) {
                            case 'Hair_1':
                                //头发
                                child.renderOrder = 3;
                                break;
                            case 'Hair_2':
                                //绑带
                                child.renderOrder = 4;
                                break;
                            case 'Hair_3':
                                //头皮
                                child.renderOrder = 5;
                                break;
                        }
                    }
                    //修改模型材质
                    if (child.material) {
                        child.material.metalness = this.modelName == 'mouse' ? 0.5 : 0;//修改金属感
                        child.material.roughness = this.modelName == 'llama' ? 1 : 1 //修改粗糙度
                    }
                });

                this.animate();
                this.$emit('show')
                //开始3分钟计时
                this.startCountdown();
                this.bubbleShow = true;
                //缓存加载过的模型到历史列表
                this.postMsg({msg: this.module, type: 'addModule'})
            }, undefined, () => {
                // 加载失败时的回调函数
                this.postMsg({ msg: '模型加载失败', type: 'back' });
            });
        },

        //三分钟计时器
        startCountdown() {
            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null; // 清空计时器引用，防止重复调用
                    this.timerBoolean = true;
                }
            }, 1000);
        },

        //循环动画
        animate() {
            this.animationId = requestAnimationFrame(this.animate);
            // 更新动画混合器
            if (this.mixer) {
                const delta = this.clock.getDelta(); // 确保clock已经正确初始化并更新
                this.mixer.update(delta);
            }
            this.renderer.render(this.scene, this.camera);
        },

        //3d动作模仿
        actionClick(type) {
            this.actionType = type ? false : true
            let jsonString = JSON.stringify({ 'signal': type ? 'motion_start' : 'motion_stop', 'target_uuid': 'romp_client', 'uuid': 'web' + this.module.uuid + "|" + this.module.modelName });
            let blob = new Blob([jsonString], { type: 'application/json' });
            this.modelSocket.send(blob);

            if (!type) {
                this.publicAction(`/app_h5/model/${this.module.modelName}_dongzuo.glb`, 0);
            }
        },

        //模型webSocket
        modelWebSocket(data, messageCount) {
            //有几句回答内容
            this.messageCount = messageCount;
            this.bubbleShow = false

            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

            var that = this;
            // 收到服务器消息时触发

            clearTimeout(this.reconnectTime);//清除定时器

            //解码处理
            if (data instanceof Blob) {

                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        //此处blob后端用的是UTF-8编码的二进制JSON
                        const jsonString = new TextDecoder('utf-8').decode(e.target.result);
                        const jsonData = JSON.parse(jsonString);

                        //转动眼球的函数
                        if ('position' in jsonData) {
                            // 获取BlendShape权重数组
                            const morphTargetInfluences = this.blendShapeMesh.morphTargetInfluences;
                            // 获取eye权重数组
                            const eyeMeshInfluences = this.eyeMesh.morphTargetInfluences;
                            // 获取当前帧的权重数据
                            const currentWeights = jsonData.position;
                            // 将权重数据设置到模型上
                            if (currentWeights && morphTargetInfluences) {
                                for (let i = 0; i < Math.min(morphTargetInfluences.length, currentWeights.length); i++) {
                                    morphTargetInfluences[i] = currentWeights[i];
                                    eyeMeshInfluences[i] = currentWeights[i];
                                }
                            }
                        } else {
                            /**
                             * 处理解析后的JSON数据
                             * 动作:{animation:.glb二进制文件,target_uuid:uuid} 
                             * 语音:{audio:.wav二进制文件,target_uuid:uuid}
                             * 脸部表情:{blendshape:.json文件,target_uuid:uuid}
                            */
                            that.handleJsonData(jsonData);
                        }
                    } catch (error) {
                        // 处理解析错误
                    }
                };
                // 读取blob数据
                reader.readAsArrayBuffer(data);
            } else {
                //流文件格式不属于Blob
            }
        },

        //模型动作、嘴型、语音处理
        async handleJsonData(data) {
            //判断是否是用内置动作
            if (this.animation && this.timerBoolean) {
                //添加内置动作
                this.actionArr.push(`/app_h5/model/${this.module.modelName}_animation.glb`)
            } else {
                //处理.glb文件
                if ('animation' in data) {
                    // 创建一个Blob对象
                    const binaryData = Uint8Array.from(data.animation, c => c.charCodeAt(0));
                    const blob = new Blob([binaryData.buffer], { type: 'application/octet-stream' });
                    if (!this.actionType) {
                        this.publicAction(URL.createObjectURL(blob), 1)
                    } else {
                        this.actionArr.push(URL.createObjectURL(blob))
                    }
                }
            }

            //处理.wav文件
            if ('audio' in data) {
                // 处理.wav文件,使用AudioContext解码音频数据
                try {
                    const binaryData = Uint8Array.from(data.audio, c => c.charCodeAt(0));
                    const arrayBuffer = binaryData.buffer;
                    const buffer = await this.audioContext.decodeAudioData(arrayBuffer);
                    const wavBlob = this.bufferToWavBlob(buffer);
                    const url = URL.createObjectURL(wavBlob);
                    this.audioBuffers.push(url);
                } catch (error) {
                    console.error("音频解码失败:", error);
                    this.postMsg({ msg: '音频解码失败:', type: 'info' });
                }
            }

            //处理blendshape文件(json文件)
            if ('blendshape' in data) {
                this.blendshapeArr.push(data.blendshape)
            }

            //确保该方法只执行一次
            if (this.audioBuffers.length == 1 && this.playNextBoolean) {
                this.playNextBoolean = false
                this.playNextAudio();
            }

        },

        //将音频缓冲区(Audio Buffer)中的数据转换为WAV格式的二进制对象(Blob)
        bufferToWavBlob(buffer) {
            const numOfChannels = buffer.numberOfChannels;
            const length = buffer.length * numOfChannels * 2 + 44;
            const bufferArray = new ArrayBuffer(length);
            const view = new DataView(bufferArray);

            this.writeString(view, 0, 'RIFF');
            view.setUint32(4, 36 + buffer.length * numOfChannels * 2, true);
            this.writeString(view, 8, 'WAVE');
            this.writeString(view, 12, 'fmt ');
            view.setUint32(16, 16, true);
            view.setUint16(20, 1, true);
            view.setUint16(22, numOfChannels, true);
            view.setUint32(24, buffer.sampleRate, true);
            view.setUint32(28, buffer.sampleRate * numOfChannels * 2, true);
            view.setUint16(32, numOfChannels * 2, true);
            view.setUint16(34, 16, true);
            this.writeString(view, 36, 'data');
            view.setUint32(40, buffer.length * numOfChannels * 2, true);

            let offset = 44;
            for (let i = 0; i < buffer.length; i++) {
                for (let channel = 0; channel < numOfChannels; channel++) {
                    const sample = buffer.getChannelData(channel)[i];
                    const s = Math.max(-1, Math.min(1, sample));
                    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
                    offset += 2;
                }
            }

            return new Blob([bufferArray], { type: 'audio/wav' });
        },

        writeString(view, offset, string) {
            for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        },

        //执行语音、嘴型、动作对齐逻辑
        playNextAudio() {
            if (this.audioBuffers[this.currentBufferIndex] !== undefined) {
                if (this.actionArr[this.currentBufferIndex] !== undefined && this.blendshapeArr[this.currentBufferIndex] !== undefined) {
                    
                    this.audio = new Audio(this.audioBuffers[this.currentBufferIndex]);
                    this.audio.play();

                    //逐个打印文字
                    this.$emit('typewriterEffect',this.currentBufferIndex);

                    // 脸部表情更新函数
                    this.currentTime = 0; // 计时器
                    this.currentFrame = 0; // 当前帧
                    this.framesPerSecond = 60; // 每秒帧数
                    this.updateInterval = 1000 / this.framesPerSecond; // 更新间隔
                    this.updateFaceExpressions()

                    //执行内置动作
                    if (this.currentBufferIndex == 0 && this.animation) {
                        this.publicAction(this.actionArr[this.currentBufferIndex], 0);
                    }

                    //执行ai动作
                    if (!this.animation) {
                        this.publicAction(this.actionArr[this.currentBufferIndex], 1);
                    }

                    // 监听播放结束事件
                    this.audio.onended = () => {
                        this.postMsg({ msg: '当前音频播放完成:', type: 'info' });
                        URL.revokeObjectURL(this.audioBuffers[this.currentBufferIndex]);
                        this.audio = null; // 清除当前音频引用
                        this.currentBufferIndex++; //增加播放索引
                        clearTimeout(this.updateTimer);//清除定时器
                        this.playNextAudio(); // 播放下一个音频
                    };
                } else {
                    this.playNextAudioTimer = setTimeout(() => {
                        this.playNextAudio()
                    }, 100);
                }
            } else {
                if (this.messageCount == this.currentBufferIndex) {
                    this.publicAction(`/app_h5/model/${this.module.modelName}_dongzuo.glb`, 0);
                    this.audioBuffers = []; //初始化音频数组
                    this.actionArr = [];//初始化动作数组
                    this.blendshapeArr = [];//初始化脸部表情数组
                    this.currentBufferIndex = 0;//初始化播放数组下标
                    this.playNextBoolean = true;
                    this.animation = true
                    //传参给后端
                    this.$emit('asrSocket', 2)
                    //播放完成情况计数值
                    this.$emit('cleanMessageCount');
                    //清除计时器
                    clearTimeout(this.playNextAudioTimer);
                    this.playNextAudioTimer = null;
                } else {
                    this.playNextAudioTimer = setTimeout(() => {
                        this.playNextAudio()
                    }, 100);
                }
            }
        },

        //脸部表情更新函数
        updateFaceExpressions() {
            this.currentTime += 1 / this.framesPerSecond; // 更新时间
            this.currentFrame = Math.floor(this.currentTime * this.framesPerSecond); // 计算当前帧
            // 确保currentFrame在有效范围内
            this.currentFrame = Math.min(this.currentFrame, this.blendshapeArr[this.currentBufferIndex].length - 1);

            // 获取BlendShape权重数组
            const morphTargetInfluences = this.blendShapeMesh.morphTargetInfluences;
            // 获取当前帧的权重数据
            const currentWeights = this.blendshapeArr[this.currentBufferIndex][this.currentFrame];

            // 将权重数据设置到模型上
            if (currentWeights && morphTargetInfluences) {
                for (let i = 0; i < Math.min(morphTargetInfluences.length, currentWeights.length); i++) {
                    morphTargetInfluences[i] = currentWeights[i];
                }
            }

            // //上牙
            // const teethOnefluences = this.teethOneMesh.morphTargetInfluences;
            // // 将权重数据设置到模型上
            // if (currentWeights && teethOnefluences) {
            //     for (let i = 0; i < Math.min(teethOnefluences.length, currentWeights.length); i++) {
            //         teethOnefluences[i] = currentWeights[i];
            //     }
            // }

            // //下牙
            // const teethTwofluences = this.teethOneMesh.morphTargetInfluences;
            // // 将权重数据设置到模型上
            // if (currentWeights && teethTwofluences) {
            //     for (let i = 0; i < Math.min(teethTwofluences.length, currentWeights.length); i++) {
            //         teethTwofluences[i] = currentWeights[i];
            //     }
            // }

            this.updateTimer = setTimeout(this.updateFaceExpressions.bind(this), this.updateInterval);
        },

        //执行模型动作
        publicAction(url, type) {
            //执行动作
            const loader = new GLTFLoader();
            loader.load(url, (gltfData) => {

                if (type == 0) {
                    // BlendShape初始化
                    this.currentModel.traverse((child) => {
                        if (child.isMesh) {
                            //脸部表情
                            if (child.name == 'Head' || child.name == 'Head_1') {
                                // 将所有 BlendShape 权重设置为 0
                                for (let i = 0; i < child.morphTargetInfluences.length; i++) {
                                    child.morphTargetInfluences[i] = 0;
                                }
                            }
                        }
                    });
                }

                // 停止前一个动画（如果存在）
                if (this.mixer) {
                    this.mixer.stopAllAction();
                    this.mixer.uncacheRoot(gltfData);//释放内存
                }

                //创建动画混合器
                this.mixer = new THREE.AnimationMixer(this.currentModel);
                // 初始化动画状态
                this.currentAnimation = this.mixer.clipAction(gltfData.animations[0]);

                // 设置动画的结束回调
                this.currentAnimation.setLoop(type == 0 ? THREE.LoopRepeat : THREE.LoopOnce);//是否循环播放动作

                this.currentAnimation.timeScale = 1; //默认1，可以调节播放速度
                // 设置 clampWhenFinished 为 true，动画播放完毕后会停留在最后一帧
                this.currentAnimation.clampWhenFinished = true;
                // 监听动画完成的事件
                this.currentAnimation.play();//播放动画
            });
        },

        //自适应屏幕
        onWindowResize() {
            // 更新相机纵横比  
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            // 更新渲染器尺寸  
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },

        //各模型大小
        modelSize(modelName) {
            var y = ''
            switch (modelName) {
                case 'xiaoan':
                    y = 23;
                    break;
                case 'alan':
                case 'weimo':
                    y = 25;
                    break;
                case 'lulu':
                    y = 33;
                    break;
                default:
                    break;
            }
            return y;
        },

        //各模型参数适配
        modelAdatation(modelName) {
            var y = ''
            switch (modelName) {
                case 'xiaoan':
                case 'weimo':
                case 'alan':
                case 'lulu':
                    y = -0.91;
                    break;
                default:
                    break;
            }
            return y;
        },

        //给app传递消息
        postMsg(info) {
            this.$webUni.postMessage({
                data: {
                    action: "appSaveMsgInfo",
                    params: info,
                }
            })
        },

        //清空数据
        clearUpdata() {
            //清除模型动画
            cancelAnimationFrame(this.animationId);
            this.animationId = null;

            // this.actionClick(false)

            // 清理资源，移除事件监听器  
            window.removeEventListener('resize', this.onWindowResize, false);
            if (this.renderer) {
                this.renderer.dispose();
            }
        }
    }
}
</script>
<style scoped>
.threeScene {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
}

.thought-bubble {
    position: absolute;
    background: #f0f0f0;
    border-radius: 15px;
    padding: 20px 20px;
    margin: 20px;
    font-size: 16px;
    color: #333;
    right: 5px;
    top: 45px;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.thought-text {
    text-align: center;
    font-size: 15px;
    font-weight: bolder;
}

.small-bubble {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #f0f0f0;
    border-radius: 50%;
    bottom: -20px;
    left: -10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.tiny-bubble {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #f0f0f0;
    border-radius: 50%;
    bottom: -40px;
    left: -15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>