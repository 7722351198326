<template>
    <div class="page">
        <!--显示帧率-->
        <div v-if="fpsShow" class="test">{{ testFps }}</div>
        <canvas class="canvas" ref="canvas"></canvas>
    </div>
</template>
<script>
export default {
    data() {
        return {
            coord: null,
            voice: null,
            canvas: null,
            ctx: null,
            currentFrame: 0, // 当前帧
            frameCount: 100, // 总帧数
            direction: 1, // 1表示正向，-1表示反向
            intervalId: null, // 定时器ID
            typeIndex: 0,//嘴型数组下标
            typeArr: [],//嘴型数组
            uuid: localStorage.getItem("uuid"),
            vsimeBoolean: true,
            audioArr: [],//音频数组
            audioIndex: 0,//音频数组下标
            img: null,
            images: [], // 存储加载的大图
            imagesMix: [],//存储加载的小图
            audioContext: null,
            playBoolean: false,
            source: null,
            audio: null,
            startTime: 0,
            timeDiff: 0,//补帧时间差
            fps: 80,//播放帧率
            fpsShow: true,//测试用(显示帧率)
            number: 0,
            testFps: 0,
            timeA: 0,
            visemName: ''//模型名称
        };
    },

    beforeDestroy() {
        // 停止动画  
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }

        this.images = [];
        this.imagesMix = []; // 如果不再需要，也清空这个数组  

        // 清理Canvas相关资源  
        this.canvas = null;
        this.ctx = null;
        this.img = null;

        // 停止任何正在进行的音频操作
        if (this.audioContext) {
            this.audioContext.close(); // 关闭AudioContext  
            this.audioContext = null;
        }
    },

    methods: {

        //更新数据
        async Update(data) {
            this.visemName = data.modelName;
            // 动态导入 JSON 文件
            try {
                const coordResponse = await fetch(`/app_h5/${this.visemName}/coord.json`);
                const voiceResponse = await fetch(`/app_h5/${this.visemName}/voice.json`);
                if (!coordResponse.ok || !voiceResponse.ok) {
                    throw new Error('Network response was not ok');
                }
                this.coord = await coordResponse.json();
                this.voice = await voiceResponse.json();

                this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
                this.initCanvas();
            } catch (error) {
                console.error('Error loading JSON files:', error);
            }
        },

        //实例化canvas
        async initCanvas() {
            this.canvas = this.$refs.canvas;
            this.ctx = this.canvas.getContext('2d');
            try {
                await Promise.all([
                    this.preloadImages(100),//缓存大图总帧数
                    this.preloadImagesMix(100, 15)//缓存小图总帧数
                ]);
                this.startAnimation();
                this.$emit('visemeShow',this.voice);
            } catch (err) {
                console.error('Error loading images:', err);
            }
        },

        //预加载大图
        preloadImages(frameCount) {
            return new Promise((resolve, reject) => {
                const urls = this.getFrameURLs(frameCount);
                const images = urls.map(src => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = () => resolve(img);
                        img.onerror = reject;
                        img.src = src;
                    });
                });

                Promise.all(images).then((loadedImages) => {
                    this.images = loadedImages;
                    resolve();
                }).catch(err => {
                    reject(err);
                });
            });
        },

        //获取大图所有帧的URL  
        getFrameURLs(frameCount) {
            let urls = [];
            for (let i = 0; i < frameCount; i++) {
                urls.push(`/app_h5/${this.visemName}/src/${i}.jpg`);
            }
            return urls;
        },

        // 预加载小图
        preloadImagesMix(frameCount, count) {
            return new Promise((resolve, reject) => {
                const promises = [];
                for (let i = 0; i < frameCount; i++) {
                    const framePromises = [];
                    for (let j = 0; j < count; j++) {
                        const nextPromises = [];
                        for (let k = j; k < count; k++) {
                            nextPromises.push(new Promise((resolve, reject) => {
                                const img = new Image();
                                img.onload = () => resolve(img);
                                img.onerror = reject;
                                img.src = `/app_h5/${this.visemName}/viseme/${i}/${j}/${k}.jpg`;
                            }));
                        }
                        framePromises.push(Promise.all(nextPromises));
                    }
                    promises.push(Promise.all(framePromises));
                }
                Promise.all(promises).then(frameImagesArrays => {
                    this.imagesMix = frameImagesArrays;
                    resolve();
                }).catch(err => {
                    reject(err);
                });
            });
        },

        // 将大图和小图画上画布
        async drawFrameAsync(frameNum) {

            // 绘制大图
            this.canvas.width = this.images[frameNum - 1].width;
            this.canvas.height = this.images[frameNum - 1].height;
            this.ctx.drawImage(this.images[frameNum - 1], 0, 0);

            if (this.typeArr[this.audioIndex] !== undefined && this.audioArr[this.audioIndex] !== undefined) {
                const currentViseme = this.typeArr[this.audioIndex];
                if (this.typeIndex < currentViseme.length) {

                    // 开始播放音频
                    if (this.vsimeBoolean) {
                        // 确保该方法只执行一次
                        this.vsimeBoolean = false;
                        this.playAudio();
                    }

                    const tagA = parseInt(this.typeArr[this.audioIndex][this.typeIndex]);
                    const tagB = parseInt(this.typeArr[this.audioIndex][this.typeIndex + 1] == undefined ? tagA : this.typeArr[this.audioIndex][this.typeIndex + 1]);

                    // 插帧原图片
                    this.handleTaskResult(this.imagesMix[frameNum - 1][tagA][0], frameNum - 1);

                    await this.sleep(25)
                    // 补帧图片
                    this.handleTaskResult(this.imagesMix[frameNum - 1][tagA > tagB ? tagB : tagA][Math.abs(tagA - tagB)], frameNum - 1);
                    this.fps = 40

                    //帧率开关(测试用)
                    this.number = this.number + 2
                    if (this.number == 2) {
                        this.timeA = Date.now();
                    }
                    if (this.number == 102) {
                        const timeB = Date.now();
                        this.testFps = 100000 / (timeB - this.timeA);
                        this.number = 0
                    }

                    this.typeIndex++;

                } else {
                    await this.sleep(100)
                    // 当前 viseme 播放完毕，切换到下一句
                    this.typeIndex = 0;
                    this.audioIndex++;
                    this.vsimeBoolean = true;
                    if (this.audioIndex == this.typeArr.length) {
                        this.resetPlayerState();
                    }
                }
            }
        },

        // 等待指定的毫秒数
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        //绘制小图以及插帧图canvas
        async handleTaskResult(frameImg, frameNum) {
            const x = this.coord[frameNum][2];
            const y = this.coord[frameNum][0];

            // 将小图转换成 ImageData 对象
            const frameImgData = await this.imageToImageData(frameImg);
            // 动态修改 alpha 通道
            // const modifiedData = this.modifyAlpha(frameImgData.data, frameImgData.width, frameImgData.height, x, y);
            // 创建新的 ImageData 对象
            const newImageData = new ImageData(frameImgData.data, frameImgData.width, frameImgData.height);
            // 将融合后的图像绘制到 canvas 上
            this.ctx.putImageData(newImageData, x, y);
        },

        // 将图片转换成 ImageData 对象
        async imageToImageData(image) {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            return ctx.getImageData(0, 0, image.width, image.height);
        },

        //动态修改alpha 通道
        modifyAlpha(rgbaData, width, height, x, y) {
            // 融合第二张图片
            const imageData = this.ctx.getImageData(x, y, width, height);
            const data = imageData.data;
            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    const index = (y * width + x) * 4;
                    const alpha = this.mask[y][x] / 255;
                    // 计算融合后的颜色值
                    data[index] = data[index] * (1 - alpha) + rgbaData[index] * alpha;
                    data[index + 1] = data[index + 1] * (1 - alpha) + rgbaData[index + 1] * alpha;
                    data[index + 2] = data[index + 2] * (1 - alpha) + rgbaData[index + 2] * alpha;
                    data[index + 3] = 255; // 设置 alpha 通道为不透明
                }
            }
            return data;
        },

        async updateFrameAsync() {

            // 更新当前帧
            this.currentFrame += this.direction;
            // 检查是否超出范围并调整
            if (this.currentFrame > this.frameCount) {
                this.currentFrame = this.frameCount - 1; // 保持在最后一帧，但下一帧会反向
                this.direction = -1;
            } else if (this.currentFrame < 1) {
                this.currentFrame = 2; // 保持在第一帧，但下一帧会正向
                this.direction = 1;
            }
            // 绘制当前帧  
            await this.drawFrameAsync(this.currentFrame);
            this.intervalId = setTimeout(this.updateFrameAsync.bind(this), this.fps);
        },

        startAnimation() {
            // 清除之前可能存在的定时器  
            if (this.intervalId) {
                clearTimeout(this.intervalId);
            }
            // 开始动画  
            this.updateFrameAsync();
        },

        async getVismeInfo(data) {

            //脸部表情处理
            if ('viseme_list' in data) {
                this.typeArr.push(data.viseme_list)
            }

            //音频处理
            if ('audio_content' in data) {
                try {
                    // const binaryData = atob(data.audio_content).split('').map(char => char.charCodeAt(0));
                    // const arrayBuffer = new Uint8Array(binaryData).buffer;
                    // 步骤 1：解码 Base64 到 ArrayBuffer
                    const binaryString = atob(data.audio_content);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const arrayBuffer = bytes.buffer;
                    const buffer = await this.audioContext.decodeAudioData(arrayBuffer);
                    const wavBlob = await this.bufferToWavBlob(buffer);
                    const url = URL.createObjectURL(wavBlob);
                    this.audioArr.push(url);
                } catch (error) {
                    console.error("音频解码失败:", error);
                }
            }
        },

        //将音频缓冲区(Audio Buffer)中的数据转换为WAV格式的二进制对象(Blob)
        bufferToWavBlob(buffer) {
            const numOfChannels = buffer.numberOfChannels;
            const length = buffer.length * numOfChannels * 2 + 44;
            const bufferArray = new ArrayBuffer(length);
            const view = new DataView(bufferArray);

            this.writeString(view, 0, 'RIFF');
            view.setUint32(4, 36 + buffer.length * numOfChannels * 2, true);
            this.writeString(view, 8, 'WAVE');
            this.writeString(view, 12, 'fmt ');
            view.setUint32(16, 16, true);
            view.setUint16(20, 1, true);
            view.setUint16(22, numOfChannels, true);
            view.setUint32(24, buffer.sampleRate, true);
            view.setUint32(28, buffer.sampleRate * numOfChannels * 2, true);
            view.setUint16(32, numOfChannels * 2, true);
            view.setUint16(34, 16, true);
            this.writeString(view, 36, 'data');
            view.setUint32(40, buffer.length * numOfChannels * 2, true);

            let offset = 44;
            for (let i = 0; i < buffer.length; i++) {
                for (let channel = 0; channel < numOfChannels; channel++) {
                    const sample = buffer.getChannelData(channel)[i];
                    const s = Math.max(-1, Math.min(1, sample));
                    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
                    offset += 2;
                }
            }

            return new Blob([bufferArray], { type: 'audio/wav' });
        },

        writeString(view, offset, string) {
            for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        },

        //播放
        playAudio() {
            //重置audio
            if (this.audio) {
                this.audio.pause()
                this.audio.onended = null;
                this.audio = null; // 清除源引用
            }

            this.audio = new Audio(this.audioArr[this.audioIndex]);

            //设置音频播放速度
            // this.audio.playbackRate = 0.83;
            this.audio.play();

            //逐个打印文字
            this.$emit('typewriterEffect', this.audioIndex);

            //当前播放完成
            this.audio.onended = () => {
                URL.revokeObjectURL(this.audioArr[this.audioIndex]);
            };

            //播放错误
            this.audio.onerror = () => {
                URL.revokeObjectURL(this.audioArr[this.audioIndex]);
            };
        },

        //重置数据
        resetPlayerState() {
            this.fps = 80
            this.audioIndex = 0;
            this.audioArr = [];
            this.vsimeBoolean = true;
            this.typeArr = [];
            this.typeIndex = 0;
            this.playBoolean = false;

            // 停止音频源
            if (this.audio) {
                this.audio.pause()
                this.audio.onended = null;
                this.audio = null; // 清除源引用
            }
        },

        //给app传递消息
        // postMsg(info) {
        //     this.$webUni.postMessage({
        //         data: {
        //             action: "appSaveMsgInfo",
        //             params: info,
        //         }
        //     })
        // }

    }
};
</script>
<style scoped>
.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.canvas {
    width: 100%;
    height: auto;
    /* object-fit: cover; */
}

.test {
    position: absolute;
    top: 100px;
    left: 50px;
    z-index: 999;
    color: red;
}
</style>